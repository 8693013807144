import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./assets/css/index.less";
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from './http/request'

Vue.use(Vant)

Vue.config.productionTip = false
Vue.prototype.$axios = axios //
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
